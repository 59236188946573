import TallPinesLogo from '../assets/tall-pines-tackle-logo.svg';
import MedalistTackleLogo from '../assets/medalist-tackle-logo.svg';



function IntroSection() {
    return (
        <section class="section introduction" id="about">
            <div className="columns">
                <div className="column is-half">
                    <p className="intro text-white has-text-centered-mobile"><strong>Tall Pines Tackle Inc/Medalist Tackle S.R.L.</strong> is the largest fishing tackle manufacturer in the Western Hemisphere. We provide O.E.M. products for over 50 national brands as well as a significant amount of private label products, manufactured in our Walmart-certified factories in a Dominican Republic free trade zone.</p>
                </div>
                <div className="column is-one-third is-offset-1">
                    <div className="columns is-mobile">
                        <div className="column is-half-tablet is-one-third-mobile is-offset-2-mobile">
                            <img className="img-responsive" src={TallPinesLogo} alt="Tall Pines Tackle" />
                        </div>
                        <div className="column is-half-tablet is-one-third-mobile">
                            <img className="img-responsive" src={MedalistTackleLogo} alt="Medalist Tackle Logo" />
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>
    );
}
export default IntroSection;