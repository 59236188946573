import React from 'react';
import headerGraphic from '../assets/medallist-tackle-header.jpg';
import headerGraphicMobile from '../assets/medallist-tackle-header-mobile.jpg';
import edgeMask from '../assets/bottom-mask.svg';

const HeroMask = ({ imageUrl }) => {
    return (
        <div className="hero-image-container">
            <div className="edge-container">
                <img src={edgeMask} alt="Monkey face" style={{ width: '100%', height: 'auto' }}></img>                 
           </div>
            <div className="hero-image-desktop is-hidden-touch">
                <img className="masked-image" src={headerGraphic} alt="Tall Pines Tackle Logo" />
            </div>
            <div className="hero-image-mobile is-hidden-desktop">
                <img className="masked-image" src={headerGraphicMobile} alt="Tall Pines Tackle Logo" />
            </div>
        </div>

    );
}

export default HeroMask;