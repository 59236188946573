import React from 'react';
import logo from '../assets/tall-pines-tackle-logo.svg';
import footerBorder from '../assets/footer-border.svg';

const Footer = ({ imageUrl }) => {
    const currentYear = new Date().getFullYear();
    
    return (
        <div>
            <div className="edge-container">
                <img src={footerBorder} alt="Decorative Jagged Footer Edge" style={{ width: '100%', height: 'auto', display: 'block', marginBottom: '-10px' }}></img>                 
            </div>
            <div className="has-background-light-grey">
                <section class="section introduction" id="about">
                    <div className="columns" style={{ alignItems: 'center' }}> 
                        <div className="column is-half">
                            <img className="" src={logo} alt="Logo" />
                        </div>
                        <div className="column is-half text-right">
                            &copy; {currentYear} Tall Pines/Medalist Tackle. All Rights Reserved.
                        </div>
                    </div>
                </section>
            </div>
            
        </div>

    );
}

export default Footer;