import React, { useState, useRef, useEffect } from 'react';
import logo from './assets/tall-pines-tackle-logo.svg';
import reverseLogo from './assets/tall-pines-tackle-logo-reverse.svg';
import { Link, animateScroll as scroll } from "react-scroll";


const Navigation = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isFloated, setIsFloated] = useState(false);
  const [isBufferZone, setIsBufferZone] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [isReverseLogoLoaded, setIsReverseLogoLoaded] = useState(false);
   const [isActive, setisActive] = React.useState(false);
  
  const navbarRef = useRef(null);

  // Navbar Burger
  const toggleBurgerMenu = () => {
    setisActive(!isActive);
  }

  useEffect(() => {

    // Load Images
      const handleLogoLoad = () => {
        setIsLogoLoaded(true);
        getNavbarHeight();
      };

      const handleReverseLogoLoad = () => {
        setIsReverseLogoLoaded(true);
        getNavbarHeight();
    };
    
      const logoImage = new Image();
      logoImage.src = logo;
      logoImage.addEventListener('load', handleLogoLoad);

      const reverseLogoImage = new Image();
      reverseLogoImage.src = reverseLogo;
      reverseLogoImage.addEventListener('load', handleReverseLogoLoad);
    

      
    // Get Navbar Height
      const getNavbarHeight = () => {
        const navbarHeight = navbarRef.current.offsetHeight;
        return navbarHeight;
      }

      window.addEventListener('resize', getNavbarHeight);

    // Hide Menu on Scroll Down
      const handleScrollDown = () => {
        const currentScrollPos = window.pageYOffset;
        const navbarHeight = getNavbarHeight();
        
        setIsHidden(currentScrollPos > prevScrollPos && currentScrollPos > (navbarHeight));
        setIsBufferZone(currentScrollPos > (navbarHeight * 3));
        setIsFloated(currentScrollPos > (navbarHeight * 1.5));
        
        console.log("Buffer Zone: " + isBufferZone, " ", "Floated: " + isFloated);
        setPrevScrollPos(currentScrollPos);
      };

    

    // Show on Scroll Up
      const handleScrollUp = () => {
        // const midScrollPosition = window.innerHeight / 2;
        // setIsFloated(prevScrollPos >= midScrollPosition && prevScrollPos <= window.innerHeight);
      };

      window.addEventListener('scroll', handleScrollDown);
      window.addEventListener('scroll', handleScrollUp);

    return () => {
      window.removeEventListener('scroll', handleScrollDown);
      window.removeEventListener('scroll', handleScrollUp);
      window.removeEventListener('resize', getNavbarHeight);
      logoImage.removeEventListener('load', handleLogoLoad);
      reverseLogoImage.removeEventListener('load', handleReverseLogoLoad);
    };
  }, [prevScrollPos]);

    return (
      <div className={`navigation ${isFloated ? 'is-floated' : ''}`}>
        <section className="">
          <nav className={`section padding-half navbar ${(isHidden || (isFloated && !isBufferZone)) ? 'hidden' : ''} ${!isFloated ? 'is-transparent' : ''}`} role="navigation" aria-label="main navigation" ref={navbarRef}>
              <div className="navbar-brand">
              <a className="navbar-logo" href="#home">
                    {(isLogoLoaded && !isFloated) && <img className="navbar-brand-logo" src={logo} alt="Logo" />}
                    {(isReverseLogoLoaded && isFloated) && <img className="navbar-brand-logo" src={reverseLogo} alt="Reverse Logo" />}
                  </a>
          
              <a
                onClick={toggleBurgerMenu}
                role="button"
                className="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbar">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </a>
              </div>
          
              <div id="navbar" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                  <div className="navbar-end">
                    <Link className="navbar-item"
                        activeClass="active"
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={toggleBurgerMenu}>
                      About
                    </Link>
                    <Link className="navbar-item"
                        activeClass="active"
                        to="people"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={toggleBurgerMenu}>
                      People
                    </Link>

                    <Link className="navbar-item"
                        activeClass="active"
                        to="capabilities"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={toggleBurgerMenu}>
                      Capabilities
                    </Link>
          
                    <Link className="navbar-item"
                        activeClass="active"
                        to="facilities"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={toggleBurgerMenu}>
                      Facilities
                    </Link>

                    <Link className="navbar-item"
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={toggleBurgerMenu}>
                      Contact Us
                    </Link>
          
          
                  </div>
          
              </div>
          </nav>
      </section>

      </div>

  );
};

export default Navigation;