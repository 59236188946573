import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import worldClassImage from '../assets/world-class.jpg';


function WorldClass() {
    return (
        <section class="section page-grid accordion-section">
            <div className="grid accordion-container">
                <div class="accordion-container__title is-hidden-tablet">
                    <h2 className="h1 display-1 text-shadow">
                        World-class <strong>tackle manufacturer</strong>
                    </h2>
                </div>
                <div className="accordion-container__content">
                    <h2 className="h1 display-1 is-hidden-mobile text-shadow">
                        World-class <strong>tackle manufacturer</strong>
                    </h2>
                    
                    <Accordion preExpanded={[0]}>
                        <AccordionItem  uuid={0}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Our Mission
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Our goal is to be the supplier of choice by providing an outstanding level of service, the highest standard of product, and helping all stakeholders achieve optimum success every step of the way.
                                </p>
                                <p>
                                    Medalist Tackle leads by example with a competitive and passionate spirit. Building long lasting relationships and upholding fair and honest values are of utmost importance.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Our Values
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We will always operate under the three T’s: Truth, Trust and Transparency.
                                </p>
                                <p>
                                    As partners we strongly believe that the best way forward is an open transparent relationship. You need accurate, reliable reporting that you can trust and we are committed to delivering just that. Let us help develop business together fostering a relationship of trust and growth for all!
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    
                </div>

                <div className="accordion-container__image">
                    <img src={worldClassImage} alt="A selection of fishing tackle in a row - High quality and colorful " />
                </div>

            </div>
        </section>
    );
}
export default WorldClass;