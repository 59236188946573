import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
// or only core styles
import '@splidejs/react-splide/css/core';

import BuildingImage1 from '../assets/building1.jpg';
import BuildingImage2 from '../assets/building2.jpg';
import BuildingImage3 from '../assets/building3.jpg';
import BuildingImage4 from '../assets/building4.jpg';
import BuildingImage5 from '../assets/building5.jpg';

function Facilities() {
    const slideRef = useRef(null)
    
      const [padding, setPadding] = useState({right: '50px', left: '0'})
    
    useEffect(() => {
        slideRef?.current?.splide?.on('moved', () => {
            const currentIndex = slideRef?.current?.splide.index
            const slideLength = slideRef?.current?.splide.length
            const slidePerPage = slideRef?.current?.splide.options.perPage

    
            if (slideLength - currentIndex === slidePerPage) {
                setPadding({ right: '0', left: '50px' })
                console.log("true")
            } else {
                setPadding({ right: '50px', left: '0px' })
            }
        })
    });

    return (
        <section class="section page-grid" id="facilities">
            <h2 className="h1 display-1 text-shadow text-white mb-5">
                Facilities
            </h2>
            <Splide
                className=" left-nav"
                ref={slideRef}
                hasTrack={false}
                  options={ {
                    rewind: true,
                      gap: '1rem',
                      pagination: false,
                      perMove: 1,
                      perPage: 3,
                      breakpoints: {
                            1024: {
                            perPage: 3,
                            
                            },
                            767: {
                            perPage: 2,
                        
                            },
                            640: {
                            perPage: 1,
                        
                            },
                        },
                        wheel: false,
                      padding: {right: padding.right, left: padding.left},
                    }}
                    aria-label="Our Capabilities">
                <SplideTrack className= 'splide-track-padding' >
                    <SplideSlide>
                        <div className="carousel-item">
                            <img className="carousel-item__image" src={BuildingImage1} alt="A selection of fishing tackle in a row - High quality and colorful " />
                            <div class="carousel-item__content">
                                <h4 className="display-5 text-orange">Building 1</h4>
                                <div className="display-6 text-light-grey mb-4">20,000 SQ. FT</div>
                                <ul className="text-white">
                                    <li>Silicone Molds preparation room</li>
                                    <li>Molding</li>
                                    <li>Trimming</li>
                                    <li>WIP</li>
                                    <li>3D Lab</li>
                                </ul>

                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="carousel-item">
                            <img className="carousel-item__image" src={BuildingImage2} alt="A selection of fishing tackle in a row - High quality and colorful " />
                            <div class="carousel-item__content">
                                <h4 className="display-5 text-orange">Building 2</h4>
                                <div className="display-6 text-light-grey mb-4">20,000 SQ. FT</div>
                                <ul className="text-white">
                                    <li>Racking</li>
                                    <li>Dipping </li>
                                    <li>Airbrushing</li>
                                    <li>Eyeing</li>
                                    <li>Cleaning</li>
                                    <li>UV </li>
                                </ul>
                            </div>
                        </div>

                    </SplideSlide>
                    <SplideSlide>
                        <div className="carousel-item">
                            <img className="carousel-item__image" src={BuildingImage3} alt="A selection of fishing tackle in a row - High quality and colorful " />
                            <div class="carousel-item__content">
                                <h4 className="display-5 text-orange">Building 3</h4>
                                <div className="display-6 text-light-grey mb-4">20,000 SQ. FT</div>
                                    <ul className="text-white">
                                        <li>Soft baits</li>
                                        <li>Tying</li>
                                        <li>Skirts</li>
                                        <li>WIP</li>
                                    </ul>                                
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="carousel-item">
                            <img className="carousel-item__image" src={BuildingImage4} alt="A selection of fishing tackle in a row - High quality and colorful " />
                            <div class="carousel-item__content">
                                <h4 className="display-5 text-orange">Building 4</h4>
                                <div className="display-6 text-light-grey mb-4">20,000 SQ. FT</div>
                                <ul className="text-white">
                                    <li>Warehouse</li>
                                </ul>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="carousel-item">
                            <img className="carousel-item__image" src={BuildingImage5} alt="A selection of fishing tackle in a row - High quality and colorful " />
                            <div class="carousel-item__content">
                                <h4 class="display-5 text-orange">Building 5</h4>
                                <div className="display-6 text-light-grey mb-4">45,000 SQ. FT</div>
                                <ul className="text-white">
                                    <li>Packaging</li>
                                    <li>Shipping</li>
                                    <li>WIP</li>
                                </ul>
                            </div>
                        </div>
                    </SplideSlide>
                </SplideTrack>

                <div class="splide__navigation-container">
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev" aria-label="Previous"><span>Prev</span></button>
                        <button className="splide__arrow splide__arrow--next" aria-label="Next"><span>Next</span></button>
                    </div>
                    
                   
                </div>

            </Splide>
        </section>
    );
}
export default Facilities;