import React, { useState, useReducer  } from 'react';
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import { FormControl, TextField } from '@mui/material';

import ReCAPTCHA from "react-google-recaptcha";


const initialState = {
  name: '',
  email: '',
  message: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.value };
    case 'email':
      return { ...state, email: action.value };
    case 'message':
      return { ...state, message: action.value };
    default:
      throw new Error();
  }
}

const CssTextField = styled(TextField)({
        color: 'white',
        fontSize: '30px',
        '& label': {
            color: 'white',
            fontSize: '30px'
        },
        '& label.MuiFormLabel-filled': {
            color: 'grey',
            fontSize: '22px',
            width: '200px'
        },
        '& label.Mui-focused, & label.Mui-active, & label.Mui-completed': {
            color: 'var(--tp-orange)',
            fontSize: '22px',
            width: 'auto'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#B2BAC2',
        },
        '& .MuiOutlinedInput-root': {
            color: 'white',
            fontSize: '22px',
            '& fieldset': {
                borderColor: 'white',
                borderWidth: '2px',
                borderRadius: '0',
                borderTopWidth: '0',
                borderLeftWidth: '0',
                borderRightWidth: '0',
                fontSize: '30px',
                color: 'white',
            },
            '&:hover fieldset': {
            borderColor: '#B2BAC2',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                borderRadius: '0',
                borderTopWidth: '0',
                borderLeftWidth: '0',
                borderRightWidth: '0',
                fontSize: '30px',
                color: 'white',
            },
        },
});
    
function MyTextField(props) {
    return  <CssTextField
        id={props.name}
        label = {props.label}
        name = {props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleValueChanged(props.name)} />
}



export const ContactUs = () => {
    const [formState, dispatch] = useReducer(reducer, initialState);

    const [formName, setFormName] = useState();
    const [formEmail, setFormEmail] = useState();
    const [formMessage, setFormMessage] = useState();

    const [showFormErr, setShowFormErr] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState({ title: '', paragraph: '' });
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [formData, setformData] = useState({ name: '', email: '', message: '' });
    
    const handleValueChanged = name => event => {
            setformData({ ...formData, [name]: event.target.value });
        }
    
    // Function that displays a success toast on bottom right of the page when form submission is successful
    const toastifySuccess = () => 
        toast('Form sent!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: 'submit-feedback success',
        toastId: 'notifyToast'
        });
    
    const toastifyFailure = (e) => 
        toast(e, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: 'submit-feedback failure',
        toastId: 'notifyToast'
        });
    

    const submitFormAndShowCaptcha = (e) => { 
        e.preventDefault();
        const params = {
            ...formData
        };
        console.log(params);
        
        if (formData.name === '' || formData.email === '' || formData.message === '' || formData.name === undefined || formData.email === undefined || formData.message === undefined) {
            setShowFormErr(true);
            return;
        } 

        setShowCaptcha(true);
    };
    
    
    // Function called on submit that uses emailjs to send email of valid contact form
    const sendEmail = (captchaValue) => {

        const params = {
            ...formData,
            'g-recaptcha-response': captchaValue,
        };
        console.log(params);
    


        const cfg = {
            service_id: process.env.REACT_APP_SERVICE_ID,
            template_id: process.env.REACT_APP_TEMPLATE_ID,
            user_ID: process.env.REACT_APP_USER_ID
        };

        console.log(cfg);
        console.log(params);


        // setFormSubmitted({ title: 'Sending message...', paragraph: '' });
        
        emailjs.send(
            cfg.service_id,
            cfg.template_id,
            params,
            cfg.user_ID).then(
            (result) => {
                console.log(result.text);
                toastifySuccess();
            },
            (error) => {
                toastifyFailure(error.text);
                console.log(error.text);
            }
        )
            .then(
                (result) => {
                    setShowThankYou(true);
                    console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );


    };
    

    
    return formSubmitted.title === '' ? (
        <section className="section page-grid contact-us" id="contact">
            <div className='columns'>
                <div className="column is-7">
                    <h2 className="h1 display-1 text-shadow text-white mb-4" style={{fontWeight: '600'}}>
                        Got ideas? We’ve got the skills. Let’s team up.
                    </h2>
                    <p className="intro text-white">Tell us more about yourself and what you’ve got in mind.</p>
                </div>
            </div>

            {!showCaptcha ? (
                <form className="contact-form" onSubmit={submitFormAndShowCaptcha} noValidate>
                    <div className="columns">
                        <div className="column is-6">
                            <div className="form-group">
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">

                                    <MyTextField
                                        label="Your name."
                                        id="name"
                                        type="text"
                                        name="name"
                                        handleValueChanged={handleValueChanged}
                                        // value={this.formName}
                                        // onChange={(e) => setFormName(e.target.value)}
                                        required
                                    />
    
                                </FormControl>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="form-group">
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">

                                    <MyTextField
                                        label="email@yourcompany.ca"
                                        id="email"
                                        type="email"
                                        name="email"
                                        handleValueChanged={handleValueChanged}
                                        // value={formEmail}
                                        // onChange={(e) => setFormEmail(e.target.value)}
                                        required
                                    />

                                </FormControl>

                            </div>

                        </div>
                    </div>

                    <div className="form-group">
                        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">

                            <MyTextField
                                label="Tell us a little about the project"
                                id="message"
                                name="message"
                                rows={10}
                                multiline
                                type="text"
                                handleValueChanged={handleValueChanged}
                                // value={formMessage}
                                // onChange={(e) => setFormMessage(e.target.value)}
                                required
                            />

                        </FormControl>
                    </div>
                    <div className="form-group">

                    </div>
                    
                    {showFormErr ? <div class="alert alert-danger" role="alert">Please fill in all three fields to send a message</div> : null}

                    <div className="text-right">
                        <input className="btn btn-cta" type="submit" value="Let's get Started" />
                        {/* <ToastContainer /> */}
                    </div>
                </form>
            ) : null };
            {showCaptcha && !showThankYou ? (
                <div>
                    <p class="intro text-white">Please complete the ReCaptcha to send your message.</p>
                    <ReCAPTCHA
                        sitekey="6LfbqPImAAAAAOWrPmsilGxxsxOQBf0Lv1wukl--"
                        onChange={sendEmail}
                    />
                </div>
            ) : null};
        
            {showThankYou ? (
                <div class="columns">
                    <div class="column is-7">
                        <h3 class="h2 display-2 text-shadow text-white mb-4" style={{fontWeight: '600'}}>Thank you.</h3>
                        <p class="intro text-white">Your message has been sent. We will be in touch with you soon.</p>
                    </div>
                </div>
            ) : null };
            
      </section>
  ) : (
    <div className="flex flex-col items-center">
      <h3 className="text-lato text-2xl font-light text-white">{formSubmitted.title}</h3>
      <p>{formSubmitted.paragraph}</p>
    </div>
    );
    
};
