import React from "react";
import UnitedStatesIcon from '../assets/united-states-vector-icon.svg'
import DominicanRepublicIcon from '../assets/dominican-republic-vector-icon.svg'



function TruthSection() {
    return (
        <>
            <section className="section page-grid truth-section">
                <h2 className="display-truth has-text-centered text-white">
                    Truth. Transparency. Trust.
                </h2>
                <div className="columns is-centered mt-5">
                    <div class="column is-4-desktop has-text-centered is-half-mobile">
                        <img src={UnitedStatesIcon} alt="A selection of fishing tackle in a row - High quality and colorful " />
                        <p className="h3 text-white">
                            Sales, customer service and shipping from the U.S.A.
                        </p>
                        
                    </div>
                    <div class="column is-4-desktop is-offset-1-desktop has-text-centered is-half-mobile">
                        <img src={DominicanRepublicIcon} alt="A selection of fishing tackle in a row - High quality and colorful " />
                        <p className="h3 text-white">
                            Proudly manufactured in the Dominican Republic
                        </p>

                    </div>


                </div>
            </section>
            <section className="section page-grid sets-apart">
                
                <div className="columns mb-5 pb-5 is-centered">
                    <div className="column is-5-desktop">
                        <p className="has-text-centered text-white">
                            Learn what sets us apart as the largest tackle manufacturer in the Western&nbsp;Hemisphere.</p>
                    </div>
                </div>
                
                <div className="columns is-centered">
                    <div className="column is-3 mr-5 ml-5">
                        <div className="display-5 text-orange has-text-centered mb-3">40ft. Containers</div>
                        <div className="display-6 text-grey has-text-centered uppercase mb-5">Arriving bi-weekly</div>
                        <p className="text-white has-text-centered">We ship and receive 40 ft. containers arriving bi-weekly, and soon to be weekly.</p>
                    </div>

                    <div className="column is-3 mr-5 ml-5">
                        <div className="display-5 text-orange has-text-centered mb-3">+500,000 pieces/day</div>
                        <div className="display-6 text-grey has-text-centered uppercase mb-5">Production capacity</div>
                        <p className="text-white has-text-centered">Our capable team is well-equipped to meet intense production demands.</p>
                    </div>

                    <div className="column is-3 mr-5 ml-5">
                        <div className="display-5 text-orange has-text-centered mb-3">7 day shipments</div>
                        <div className="display-6 text-grey has-text-centered uppercase mb-5">From factory to distribution</div>
                        <p className="text-white has-text-centered">From our factories in the Dominican to our distribution warehouse in Florida in 7 days.</p>
                    </div>
                </div>
            </section>
        </>
    );
}
export default TruthSection;