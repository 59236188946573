import React from 'react';
import MedalistFamily from '../assets/medalist-family.jpg';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import ourPeopleImage from '../assets/our-people.jpg';

function OurPeople() {
    return (
        <section class="section page-grid accordion-section" id="people">
            <div className="grid accordion-container image-right">
                <div class="accordion-container__title is-hidden-tablet">
                    <h2 className="h1 display-1 text-shadow">
                        People, partnerships <strong>&amp;&nbsp;performance</strong>
                    </h2>
                </div>
                <div className="accordion-container__content">
                    <h2 className="h1 display-1 is-hidden-mobile text-shadow">
                        People, partnerships <strong>&amp;&nbsp;performance</strong>
                    </h2>
                    
                    <Accordion preExpanded={[0]}>
                        <AccordionItem  uuid={0}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    500+ experience team members 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Our employees are instilled with the initiative to be accountable, keep promises, and act as business builders.
                                </p>
                                <p>
                                    We are driven as a team to continually advance our product, processes and services. At the heart of Medalist Tackle our vision is to be the leader and most admired for our people, partnership and performance.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Our team is a family
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Medalist Tackle is the sum of its parts, and we are proud to say that every manager, operator, administrator, and owner is part of this family! Our family is a blend of experience and education and we all take pride in delivering the best quality product for our customers. We look forward to you becoming part of that family!
                                </p>
                                <img className="img-responsive" src={MedalistFamily} alt="The Medalist Family" />
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    
                </div>

                <div className="accordion-container__image">
                    <img src={ourPeopleImage} alt="A selection of fishing tackle in a row - High quality and colorful " />
                </div>

            </div>
        </section>
    );
}
export default OurPeople;