import Navigation from './Navigation';
import HeroMask from './components/Hero-Image';
import IntroSection from './components/Introduction';
import IntroVideo from './components/IntroVideo';
import WorldClass from './components/WorldClass';
import OurPeople from './components/PeoplePartnerships';
import OurCapabilities from './components/Capabilities';
import MessageForm from './components/messageForm';
import Footer from './components/Footer';

// Image Imports

// import './App.scss';
import './Main.scss';
import OurProducts from './components/OurProducts';
import TruthSection from './components/TruthTransparancyTrust';
import Facilities from './components/Facilities';
import { ContactUs } from './components/ContactUs';

function App() {

  return (
		<div className="App">
			<Navigation />

			<header className="header">
				<div className="hero-bg">

				  <HeroMask />
				  
				</div>
				<section className="section hero-text">
					<div className="columns header-content">
						<div className="column is-three-quarters-desktop is-10-tablet">
							<h1>
							Specialized <strong>Tackle Solutions</strong>
							</h1>
							<div className="columns border-bottom is-vcentered">
								<div className="column is-6">
									<h2 className="display-3 text-white">
									Your one-stop manufacturing partner for fishing tackle.
									</h2>
								</div>
								<div className="column is-one-third is-offset-1">
									<a href="/" className="btn btn-cta arrow-down">
										Learn More
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
        
      		</header>

			<IntroSection />

			<IntroVideo />

			<WorldClass />

			<OurPeople />

			<OurCapabilities />

			{/* <OurProducts /> */}

			<TruthSection />

			<Facilities />

		  <ContactUs />
		  {/* <MessageForm /> */}

			<Footer />

      </div>
  );
}

export default App;
