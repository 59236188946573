import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


function IntroVideo() {
    return (
        <section class="section page-grid about-video">
            <div className="grid about-video-grid">
                <div className="about-video-grid__title">
                    <h2 className="h1 display-1 text-shadow">
                        <strong>About Medalist Tackle</strong>
                    </h2>
                </div>
                <div className="about-video-grid__video">
                    <LiteYouTubeEmbed 
                            id="U8wHzOUKwNk"
                            title="Introduction to Medalist Tackle"
                        />
                </div>

            </div>
        </section>
    );
}
export default IntroVideo;