import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
// or only core styles
import '@splidejs/react-splide/css/core';

import CapabilitiesImage from '../assets/IW-Capabilities-Building.jpg';
import CapabilitiesImage2 from '../assets/IW-Adding-Eyes-to-Tackle.jpg';
import CapabilitiesImage3 from '../assets/IW-Assembling-Tackle.jpg';
import CapabilitiesImage4 from '../assets/IW-Man-Pouring-Molten-Lead.jpg';
import CapabilitiesImage5 from '../assets/IW-Painting-Tackle.jpg';
import CapabilitiesImage6 from '../assets/IW-Packaging.jpg';


function OurCapabilities() {
    return (
        <section className="section page-grid capabilities" id="capabilities">
            <h2 className="h1 display-1 text-shadow">
                Our Capabilities
            </h2>
            <div class="column is-three-quarters-desktop is-10-tablet"><p class="text-white">Our design department allows us to make molds in-house, as well as robust 3d printing capabilities to bring your concept to life. From their let us mold, decorate, and package product so that you are ready for distribution and retail!</p></div>
            <Splide
                className="right-nav orange-grid"
                hasTrack={false}
                  options={ {
                    rewind: true,
                    gap: '1rem',
                    pagination: false
                    }}
                    aria-label="Our Capabilities">
                <SplideTrack>
                    <SplideSlide>
                        <div className="slider-item">
                            <img className="slider-item__image" src={CapabilitiesImage} alt="A selection of fishing tackle in a row - High quality and colorful " />
                            <div className="slider-item__content">
                                <h3 className="display-2 text-white">125,000 SQ. FT</h3>
                                <p className="display-4 uppercase text-dark-blue">Production Facility</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="slider-item">
                            <img className="slider-item__image" src={CapabilitiesImage5} alt="Painting Fishing Tacle" />
                            <div className="slider-item__content">
                                <h3 className="display-2 text-white">Painting</h3>
                                <p className="display-4 uppercase text-dark-blue">Vibrant colors bring <br />lures to life</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="slider-item">
                            <img className="slider-item__image" src={CapabilitiesImage4} alt="Pouring Molten Lead into a Fishing Tackle Mold" />
                            <div className="slider-item__content">
                                <h3 className="display-2 text-white">Molding</h3>
                                <p className="display-4 uppercase text-dark-blue">Crafting durable tackle <br />for anglers.</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="slider-item">
                            <img className="slider-item__image" src={CapabilitiesImage3} alt="Tying Fishing Lures" />
                            <div className="slider-item__content">
                                <h3 className="display-2 text-white">Tying </h3>
                                <p className="display-4 uppercase text-dark-blue">Skillful knots for <br />superior strength.</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="slider-item">
                            <img className="slider-item__image" src={CapabilitiesImage2} alt="Adding 3d Eyes to Lures" />
                            <div className="slider-item__content">
                                <h3 className="display-2 text-white">Eyeing </h3>
                                <p className="display-4 uppercase text-dark-blue">Adding realistic 3D eyes <br />to tackle</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className="slider-item">
                            <img className="slider-item__image" src={CapabilitiesImage6} alt="Packaging Lures" />
                            <div className="slider-item__content">
                                <h3 className="display-2 text-white">Packaging </h3>
                                <p className="display-4 uppercase text-dark-blue">Protecting lures for <br />safe delivery</p>
                            </div>
                        </div>
                    </SplideSlide>
                </SplideTrack>

                <div className="splide__navigation-container">
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev" aria-label="Previous"><span>Prev</span></button>
                        <button className="splide__arrow splide__arrow--next" aria-label="Next"><span>Next</span></button>
                    </div>
                    
                   
                </div>

            </Splide>
        </section>
    );
}
export default OurCapabilities;